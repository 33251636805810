import React, { useState, useEffect } from "react";
import "./Resources.css";
import Select from "react-select";
import { FaDownload } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa";
import DOMPurify from 'dompurify';
import {
  PdfImg,
  VideoImg1,
  VideoImg2,
  VideoImg3,
} from "../../SvgFiles/SvgFile";
import VideoPopup from "../VideoModel/VideoModel";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import {
  downloadResourcesTemplate,
  downloadTemplate,
  fetchUploadTemplate,
  getResourceVideoLink,
} from "../Services/UserServices";
import ApprovalMessage from "../ApprovalMessage/ApprovalMessage";
import file_correction from "../assets/File Correction.svg";
import file_verify from "../assets/Fle Approved.svg";
import file_upload from "../assets/File Upload 01.jpg";
const Resources = () => {
  const [isVideoPopupOpen, toggleIsVideoPopupOpen] = useState(false);
  const [showErrorBox, toggleShowErrorBox] = useState(false);
  const [selectedVideoURL, setSelectedVideoURL] = useState("");

  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const user = JSON.parse(localStorage.getItem("userDetails"));
  const location = useLocation();
  useEffect(() => {
    localStorage.removeItem('selectedtemplate');
    localStorage.setItem("showbasicfilter", false)
    // localStorage.removeItem('showbasicfilter');
    localStorage.removeItem('type');
    //localStorage.removeItem('selectedtemplate');
    localStorage.removeItem('restorebasicfilter');
    localStorage.removeItem('restoreadvancefilter');
    localStorage.removeItem('selectedcheckdpartner');
    localStorage.removeItem('selectedcheckdworkflow');
    localStorage.removeItem('selectedcheckdstudy');
    localStorage.removeItem('selectedcheckdmaterial');
    localStorage.removeItem('selectedcheckdfile');
    localStorage.removeItem('selectedcheckddataset');
    localStorage.removeItem('selectedcheckdstatus');
    localStorage.removeItem('partnerlistsession');
    localStorage.removeItem('workflowlistsession');
    localStorage.removeItem('studylistsession');
    localStorage.removeItem('materiallistsession');
    localStorage.removeItem('filelistsession');
    localStorage.removeItem('datasetlistsession');
    localStorage.removeItem('statuslistsession');
    localStorage.setItem("currentPath", location.pathname);
  }, []);
  const sanitizeInput = (input) => {
    // This will remove any < and > characters to prevent HTML tag injection
    return input.replaceAll(/[&/\\#,+()$~%.^'":*?<>{}]/g, "");
  }
  const openPopup = (videoURL) => {
    getResourceVideoLink(videoURL)
      .then((response) => {
        // console.log("response", response.data[0]);
        if (response.status === 200) {
          setSelectedVideoURL(response.data[0]);
          toggleIsVideoPopupOpen(true);
        }
        else {
          toggleShowErrorBox(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }
          else if (error.response.status === 400) {
            toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
          }
          else {

            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }

        //toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");

      });
    // console.log('e', e);
    // console.log("videoURL", videoURL);


  };
  useEffect(() => {
    fetchUploadTemplate({
      user_id: user.user_id,
      role_id: localStorage.getItem("role"),
      partner_id: user.partner_name,
    })
      .then((data) => {
        let templateList = data.map((item) => ({
          value: item.template_id,
          label: item.template_name,
        }));

        if (templateList.length > 2) {
          templateList[2].className = "borderBottomTemplateList";
        }

        setTemplateList(templateList);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }
          // else if (error.response.status === 400) {
          //   toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
          // }
          else if (error.response.status === 403) {
            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }
          else {

            toast.error(
              "There was an error, Template names are not populated"
            );

          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }
      });
  }, []);
  const handleDownloadTemplate = () => {
    if (selectedTemplate) {
      const templateName = selectedTemplate.label;
      downloadTemplate(templateName)
        .then((response) => {
          if (response.status === 200) {


            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            // link.href = url;
            // if (/^https?:\/\//.test(url)) {
            link.href = DOMPurify.sanitize(url);
            // } else {
            //   console.log('Invalid URL:', );
            //   // Handle the invalid URL appropriately
            //   // For example, you might set link.href to a default safe URL or leave it unchanged
            // }
            // set the visibility hidden so it will not effect on your web-layout
            link.style = "visibility:hidden";
            link.download = (templateName) + ".xlsx"; // the file name you want
            // this part will append the anchor tag and remove it after automatic click
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("Template will be downloaded shortly");
          }
          else {
            toggleShowErrorBox(true);

          }
        })
        .catch((error) => {
          //console.log("error", error);
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 401) {
              console.log("initSSO: Token invalid - let's try again,token epired");
              window.localStorage.removeItem("hashToken");
              window.localStorage.removeItem("accessToken");
              window.open(window.location.origin + "/api/link", "_self");
            }
            else if (error.response.status === 400) {
              toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
            }
            else {

              toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

            }

          } else {
            // Something happened in setting up the request that triggered an Error
            toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

          }

          //toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");

        });
    } else {
      toast.error("Please select a template to download");
    }
  };
  const handleDownloadPDF = (pdfName) => {
    downloadResourcesTemplate(pdfName.trim())
      .then((response) => {
        if (response.status === 200) {

          const url = window.URL.createObjectURL(new Blob([response.data]));
          // const url = window.URL.createObjectURL(
          // new Blob([`<body><script>alert(${response?.data})<\/script></body>`], {type : 'text/html'}))
          const link = document.createElement("a");
          //link.href = url;
          // if (/^https?:\/\//.test(url)) {
          link.href = DOMPurify.sanitize(url);
          // } else {
          //   console.log('Invalid URL:', );
          //   // Handle the invalid URL appropriately
          //   // For example, you might set link.href to a default safe URL or leave it unchanged
          // }
          // set the visibility hidden so it will not effect on your web-layout
          link.style = "visibility:hidden";
          link.download = (pdfName) + ".pdf"; // the file name you want
          // this part will append the anchor tag and remove it after automatic click
          document.body.appendChild((link));
          link.click();
          document.body.removeChild(link);
          toast.success("PDF will be downloaded shortly");
        }
        else {
          toggleShowErrorBox(true);
        }
      })
      .catch((error) => {
        //console.log("error", error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 401) {
            console.log("initSSO: Token invalid - let's try again,token epired");
            window.localStorage.removeItem("hashToken");
            window.localStorage.removeItem("accessToken");
            window.open(window.location.origin + "/api/link", "_self");
          }
          else if (error.response.status === 400) {
            toast.error("Unable to locate the resource. Please try after sometime or contact the system administrator by emailing your sponsor");
          }
          else {

            toast.error('Unauthorized access. Please contact the system administrator by emailing your sponsor.')

          }

        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error('Something went wrong. Please contact the system administrator by emailing your sponsor.')

        }



      });
  };
  return (
    <>

      <h5 className="text-center mt-3">Download Templates</h5>
      <div className="row justify-content-center mt-3">
        <div className=" col-md-5 offset-1 pr-0">
          <Select
            options={templateList}
            isSearchable={true}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderRadius: "0.25em 0em   0em 0.25em",
                borderColor: "#ced4da",
                fontSize: "0.9rem",
              }),
              option: (
                styles,
                { data, isDisabled, isFocused, isSelected }
              ) => ({
                ...styles,
                cursor: "pointer",
                display: "block",
                fontSize: "0.9rem",
                width: "100%",
                padding: "5px 12px",
                boxSizing: "border-box",
                borderBottom: data?.className ? "1px solid #8e8e8e" : "",
              }),
            }}
            classNamePrefix="selectDropdown"
            aria-label="Default select example"
            name="templateList"
            onChange={(e) => {
              setSelectedTemplate(e);
            }}
            value={selectedTemplate || ""}
            placeholder="Select Template To Download"
          />
        </div>
        <div className="col-md-2 pl-0">
          <button
            className="btn btn-primary downloadButton"
            onClick={handleDownloadTemplate}
          >
            <span className="mr-1">
              <FaDownload />
            </span>
            Download
          </button>
        </div>
      </div>
      <h5 className="help-topic text-center mt-3">Help Topics</h5>
      <div className="container mt-3">
        <div className="row">
          <div className="col-20 col">
            <div className="card cardStyling">
              <div className="card-body text-center">
                <div className="ml-2">
                  <PdfImg baseHeight='69' />
                </div>

                <div
                  onClick={() => handleDownloadPDF("Amgen DPC_Reloading Files and Data Correction R3_QRG_F")}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue mt-1"
                >
                  Data Correction
                </div>
              </div>
            </div>
          </div>
          <div className="col-20 col">
            <div className="card cardStyling">
              <div className="card-body text-center">
                <div className="ml-2">
                  <PdfImg baseHeight='69' />
                </div>

                <div
                  onClick={() =>
                    handleDownloadPDF("Amgen DPC_Reviewing and Approving Data R3_QRG_F")
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue mt-1"
                >
                  Approving Data
                </div>
              </div>
            </div>
          </div>
          <div className="col-20 col">
            <div className="card cardStyling">
              <div className="card-body text-center">
                <div className="ml-2">
                  <PdfImg baseHeight='69' />
                </div>

                <div
                  onClick={() =>
                    handleDownloadPDF(
                      "Amgen DPC Loading Data R3_QRG_F"
                    )
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue mt-1"
                >
                  Loading Data
                </div>
              </div>
            </div>
          </div>
          <div className="col-20 col">
            <div className="card cardStyling">
              <div className="card-body text-center">
                <div className="ml-2">
                  <PdfImg baseHeight='69' />
                </div>
                {/* <h1 className='card-title'><FaRegFilePdf color='#FF0000'/></h1> */}

                <div
                  onClick={() =>
                    handleDownloadPDF(
                      "Amgen DPC Template QRG_F"
                    )
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue mt-1"
                >
                  Template
                </div>
              </div>
            </div>
          </div>
          <div className="col-20 col">
            <div className="card cardStyling">
              <div className="card-body text-center">
                <div className="ml-2">
                  <PdfImg baseHeight='69' />
                </div>

                <div
                  onClick={() => handleDownloadPDF("SOP-423634")}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue mt-1"
                >
                  SOP
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 className=" text-center mt-3">Reference Video</h5>
      <div className="container mt-3">
        <div className="row px-5">
          <div className="col-4">
            <div className="card cardStyling">
              <div className=" text-center my-2">
                <div>
                  {/* <VideoImg2 /> */}
                  <img src={file_upload} alt="amgen_logo" className="img-height " />
                </div>
                <div

                  className="text-blue"
                  onClick={() =>
                    openPopup("Amgen_LoadingData_Submission_DPC_Portal_demo_v11_06122018")
                  }
                >
                  How To Upload File
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card cardStyling">
              <div className=" text-center my-2">
                <div>
                  {/* <VideoImg3 /> */}
                  <img src={file_correction} alt="amgen_logo" className="img-height " />
                </div>
                <div
                  className="text-blue"
                  onClick={() =>
                    openPopup(
                      "Amgen_Correcting_Reloading_Data_v10_06122018"
                    )
                  }
                >
                  How To Correct File
                </div>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="card cardStyling">
              <div className=" text-center my-2">
                <div>
                  {/* <VideoImg1 /> */}
                  <img src={file_verify} alt="amgen_logo" className="img-height " />
                </div>

                <div
                  className="text-blue"
                  onClick={() =>
                    openPopup("Amgen_ReviewingApproving_Files_demo_v11_06122018")
                  }
                >
                  How To Verify File
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VideoPopup
        isOpen={isVideoPopupOpen}
        toggleIsVideoPopupOpen={(e) => toggleIsVideoPopupOpen(e)}
        videoWidth={950}
        videoHeight={450}
        videoURL={selectedVideoURL}
      />

      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          className: "",

          error: {
            duration: 5000,
            icon: "❗",
          },
        }}
      />
    </>
  );
};

export default Resources;
